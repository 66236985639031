<template>
  <div class="programme">
    <div class="topImg">
      <img
        src="https://oss.pinmallzj.com/image/maintain/2023/08/10/Framex5721-nqj3gsit9kqn.png"
        alt=""
      />
      <div class="tabList acea-row row-middle row-center">
        <div
          class="tabItem pointer"
          v-for="(item, index) in sceneList"
          :key="index"
          @click="tabChange(index, item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="giftBox" id="gift" ref="giftBox">
      <div class="title pointer">礼赠品业务场景</div>
      <div class="leftItemBox acea-row row-between" ref="gift">
        <img
          src="https://oss.pinmallzj.com/image/maintain/2023/08/11/imagex436-h3iql1m3fb3k.png"
          alt=""
          class="leftBgImg"
        />
        <img
          class="imgItem"
          :class="[giftShow ? 'animated fadeInLeft' : '']"
          src="https://oss.pinmallzj.com/image/maintain/2023/08/11/Rectanglex14142-ihkyhq6jr19h.png"
          alt=""
        />
        <div
          class="infoBox pointer"
          :class="[giftShow ? ' animated fadeInRight' : '']"
          @click="goToWhere('/JRintroduction')"
        >
          <div class="name acea-row row-bottom u-pl10">
            <span class="name1">聚如SaaS</span
            ><span class="name2">（礼品服务商版本）</span>
          </div>
          <div class="infoItem u-mt26">
            该平台围绕礼品服务商业务场景，为其一键搭建专属商城并提供全品类商品库，共享项目招标信息，提供礼品定制等数字化解决方案。与国家文物局直属单位中创文贸达成战略合作关系，将共同输出文化IP。
          </div>
          <div class="more acea-row row-middle u-mt26">
            <span>了解更多</span>
            <img class="u-ml10" src="@/assets/newImgs/Arrow 2.png" alt="" />
          </div>
        </div>
      </div>
      <div class="leftItemBox acea-row row-between" ref="pj">
        <img
          src="https://oss.pinmallzj.com/image/maintain/2023/08/11/imagex437-a657cwnqr5u3.png"
          alt=""
          class="rightBgImg"
        />
        <div
          class="infoBox pointer"
          :class="[pjShow ? 'animated fadeInLeft' : '']"
          @click="goToWhere('/PJintroduction')"
        >
          <div class="name acea-row row-bottom u-pl10">
            <span class="name1">品聚SaaS</span
            ><span class="name2">（供应商版本）</span>
          </div>
          <div class="infoItem u-mt26">
            该平台围绕品牌供应商业务场景，为品牌供应商一键搭建专属商品库，库存实时同步，订单数字化管理，一键生成PPT/Excle产品手册，提高业务员业务拓展能力及效率，实现信息数字化有效管理。
          </div>
          <div class="more acea-row row-middle u-mt26">
            <span>了解更多</span>
            <img class="u-ml10" src="@/assets/newImgs/Arrow 2.png" alt="" />
          </div>
        </div>

        <img
          class="imgItem"
          :class="[pjShow ? ' animated fadeInRight' : '']"
          src="https://oss.pinmallzj.com/image/maintain/2023/08/11/Rectanglex14145-109ugrwntnq0.png"
          alt=""
        />
      </div>
      <div class="leftItemBox acea-row row-between" ref="zqt">
        <img
          src="https://oss.pinmallzj.com/image/maintain/2023/08/11/imagex436-h3iql1m3fb3k.png"
          alt=""
          class="leftBgImg"
        />
        <img
          class="imgItem"
          :class="[zqtShow ? 'animated fadeInLeft' : '']"
          src="https://oss.pinmallzj.com/image/maintain/2023/08/11/Rectanglex14142-ihkyhq6jr19h.png"
          alt=""
        />
        <div
          class="infoBox pointer"
          :class="[zqtShow ? 'animated fadeInRight' : '']"
          @click="goToWhere('/ZJTintroduction')"
        >
          <div class="name acea-row row-bottom u-pl10">
            <span class="name1">政企通SaaS</span
            ><span class="name2">（终端客户版本）</span>
          </div>
          <div class="infoItem u-mt26">
            该平台围绕企事业单位等终端业务场景，为其搭建数字化采购管理平台，助力企业创建数字化管理模式，实现数字化高效运转。
          </div>
          <div class="more acea-row row-middle u-mt26">
            <span>了解更多</span>
            <img class="u-ml10" src="@/assets/newImgs/Arrow 2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="giftBox" ref="nd" id="nd">
      <div class="title pointer">烘焙业务场景</div>
      <div class="leftItemBox acea-row row-between">
        <img
          src="https://oss.pinmallzj.com/image/maintain/2023/08/11/imagex437-a657cwnqr5u3.png"
          alt=""
          class="rightBgImg"
        />
        <div
          class="infoBox pointer"
          :class="[ndShow ? 'animated fadeInLeft' : '']"
          @click="goToWhere('/bakingintroduction')"
        >
          <div class="name acea-row row-bottom u-pl10">
            <span class="name1">暖点SaaS</span><span class="name2"></span>
          </div>
          <div class="infoItem u-mt26">
            该平台围绕烘焙业务场景，助力烘焙企业实现采销线上/线下一体化、批发/零售一体化,为其一键搭建耗材专属采购商城并提供数字化管理后台，提供烘培全品类耗材商品库，为烘焙行业降本增效，提供全场景全业务全渠道的数字化解决方案。
          </div>
          <div class="more acea-row row-middle u-mt26">
            <span>了解更多</span>
            <img class="u-ml10" src="@/assets/newImgs/Arrow 2.png" alt="" />
          </div>
        </div>

        <img
          class="imgItem"
          :class="[ndShow ? 'animated fadeInRight' : '']"
          src="https://oss.pinmallzj.com/image/maintain/2023/08/11/Rectanglex14150-hj9bmdtcapt3.png"
          alt=""
        />
      </div>
    </div>
    <div class="giftBox" ref="wm" id="wm">
      <div class="title pointer">民营口腔诊所业务场景</div>
      <div class="leftItemBox acea-row row-between">
        <img
          src="https://oss.pinmallzj.com/image/maintain/2023/08/11/imagex436-h3iql1m3fb3k.png"
          alt=""
          class="leftBgImg"
        />
        <img
          class="imgItem"
          :class="[wmShow ? 'animated fadeInLeft' : '']"
          src="https://oss.pinmallzj.com/image/maintain/2023/08/11/Rectanglex14149-bvjvipbvxd2m.png"
          alt=""
        />
        <div
          class="infoBox pointer"
          :class="[wmShow ? ' animated fadeInRight' : '']"
          @click="goToWhere('/WMintroduction')"
        >
          <div class="name acea-row row-bottom u-pl10">
            <span class="name1">蔚美SaaS</span><span class="name2"></span>
          </div>
          <div class="infoItem u-mt26">
            该平台针对民营口腔行业场景，提供针对口腔医疗行业的数字化管理解决方案。通过数字化技术的应用，蔚美SaaS帮助口腔医疗机构实现从患者预约到就诊完成的全流程数字化管理。帮助口腔医疗机构提高工作效率、提升患者体验、进而提升口腔医疗机构的品牌形象和市场竞争力。
          </div>
          <div class="more acea-row row-middle u-mt26">
            <span>了解更多</span>
            <img class="u-ml10" src="@/assets/newImgs/Arrow 2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="giftBox" ref="xy" id="xy">
      <div class="title pointer">县域经济业务场景</div>
      <div class="leftItemBox acea-row row-between">
        <img
          src="https://oss.pinmallzj.com/image/maintain/2023/08/11/imagex437-a657cwnqr5u3.png"
          alt=""
          class="rightBgImg"
        />
        <div
          class="infoBox pointer"
          :class="[xyShow ? 'animated fadeInLeft' : '']"
          @click="goToWhere('/county')"
        >
          <div class="name acea-row row-bottom u-pl10">
            <span class="name1">县有良品SaaS</span><span class="name2"></span>
          </div>
          <div class="infoItem u-mt26">
            该平台是由我们与县学研究院共同打造，旨在打造“一县一优品，一域一文化”，该平台通过县市国有龙头企业，从产品设计包装到营销推广全链路赋能，加快县域经济数字化、智能化转型，打造县域优质农业品牌，提升县域经济数字化建设水平，促进县域经济数字化转型发展。
          </div>
          <div class="more acea-row row-middle u-mt26">
            <span>了解更多</span>
            <img class="u-ml10" src="@/assets/newImgs/Arrow 2.png" alt="" />
          </div>
        </div>
        <img
          class="imgItem"
          :class="[xyShow ? ' animated fadeInRight' : '']"
          src="https://oss.pinmallzj.com/image/maintain/2023/08/11/Rectanglex14157-2uzx38pk98j9.png"
          alt=""
        />
      </div>
    </div>
    <div class="giftBox" ref="wwt" id="wwt">
      <div class="title pointer">欧洲百货店业务场景</div>
      <div class="leftItemBox acea-row row-between">
        <img
          src="https://oss.pinmallzj.com/image/maintain/2023/08/11/imagex436-h3iql1m3fb3k.png"
          alt=""
          class="leftBgImg"
        />
        <img
          class="imgItem"
          :class="[wwtShow ? 'animated fadeInLeft' : '']"
          src="https://oss.pinmallzj.com/image/maintain/2023/08/11/Rectanglex14158-27qd77og7fri.png"
          alt=""
        />
        <div
          class="infoBox pointer"
          :class="[wwtShow ? ' animated fadeInRight' : '']"
          @click="goToWhere('/WWTintroduction')"
        >
          <div class="name acea-row row-bottom u-pl10">
            <span class="name1">物物通SaaS</span><span class="name2"></span>
          </div>
          <div class="infoItem u-mt26">
            该平台围绕海外百货店业务场景，其可一键为百货店老板生成自己的专属进货小程序，旨在为海外百货店老板打造专属优质进货平台；我们向百货店老板筛选商品价格有优势、商品品类齐全、商品数量丰富、商品质量有保障、采购服务体系完善的进货仓库。平台也会自动记录百货店老板进货的数据并形成展示页面方便百货店老板一键补货，让百货店老板采货流程更加便捷、放心。
          </div>
          <div class="more acea-row row-middle u-mt26">
            <span>了解更多</span>
            <img class="u-ml10" src="@/assets/newImgs/Arrow 2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="juruBox acea-row row-column row-center-wrapper">
      <img
        class="chinese"
        src="https://oss.pinmallzj.com/image/maintain/2023/08/31/%E6%B5%99%E6%B1%9F%E8%81%9A%E5%A6%82%E7%BB%99%E6%82%A8%E6%97%A0%E9%99%90%E5%8F%AF%E8%83%BD-r1khn9uwjslp.png"
        alt=""
      />
      <img
        class="eng u-mt20"
        src="https://oss.pinmallzj.com/image/maintain/2023/08/31/ZhejiangxJuruxInformationxTechnologyxCoxxxLtdx-2iqqr51wgq9v.png"
        alt=""
      />
    </div>
    <div class="entApply acea-row row-bottom">
      <img
        src="https://oss.pinmallzj.com/image/maintain/2023/08/11/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230811114546-4cmkqzrcurnj.png"
        alt=""
      />
      <div class="rightBox">
        <div class="title">企业入驻申请</div>
        <div class="title1">即刻快速搭建商城，开启您的采购服务之旅！</div>
        <div class="btn pointer" @click="goToWhere('/entIn')">企业入驻申请</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "programme",
  data() {
    return {
      // tabIndex: 0,
      giftShow: false,
      pjShow: false,
      zqtShow: false,
      ndShow: false,
      wmShow: false,
      xyShow: false,
      wwtShow: false,
      sceneList: [
        {
          name: "礼赠品业务场景",
          id: "giftBox",
        },
        {
          name: "烘焙业务场景",
          id: "nd",
        },
        {
          name: "民营口腔诊所业务场景",
          id: "wm",
        },
        {
          name: "县域经济业务场景",
          id: "xy",
        },
        {
          name: "欧洲百货店业务场景",
          id: "wwt",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    //页面路由切换
    goToWhere(page, index) {
      this.$router.push({
        path: page,
      });
    },
    tabChange(index, id) {
      // this.tabIndex = index;
      const element = this.$refs[id]; // 替换为你的元素引用
      const rect = element.getBoundingClientRect();
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const distanceToTop = rect.top + scrollTop;
      window.scrollTo({
        top: distanceToTop - 200,
        behavior: "smooth",
      });
    },
    handleScroll() {
      // 实现当滚动到指定位置，触发动画
      // let scrollTop =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 获取窗口滚动条高度
      // this.gdjz('earth', 'earth animated bounceInDown', 50)
      let _this = this;
      let refArray = [
        { ref: "gift", show: "giftShow", delayPos: 250 },
        { ref: "pj", show: "pjShow", delayPos: 250 },
        { ref: "zqt", show: "zqtShow", delayPos: 250 },
        { ref: "nd", show: "ndShow", delayPos: 250 },
        { ref: "wm", show: "wmShow", delayPos: 250 },
        { ref: "xy", show: "xyShow", delayPos: 250 },
        { ref: "wwt", show: "wwtShow", delayPos: 250 },
      ];
      refArray.forEach((r, i) => {
        _this.gdjz(r.ref, r.delayPos, () => {
          _this[r.show] = true;
        });
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        // console.log(a, "a", b, "b", c, "c", d, "d");
        if (b + c > a) {
          callback && callback();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.programme {
  padding-top: 91px;
  scroll-behavior: smooth;
  background: #f9f8fc;
}

.topImg {
  position: relative;
  img {
    width: 100%;
    min-height: 500px;
  }
  .tabList {
    width: 80%;
    height: 142px;
    border-radius: 2px;
    border: 2px solid #fff;
    background: linear-gradient(179deg, #eff3f7 0%, #feffff 100%);
    position: absolute;
    bottom: -71px;
    left: 50%;
    transform: translateX(-50%);
    // .tabItem {
    //   padding: 12px 28px;
    //   color: #3266f6;
    //   text-align: center;
    //   font-family: PingFang SC;
    //   font-size: 25px;
    //   font-style: normal;
    //   font-weight: 500;
    //   line-height: 100%; /* 24.84px */
    //   letter-spacing: 0.497px;
    //   transition: all 0.3s linear;
    //   &.active {
    //     color: #fff;
    //     border-radius: 3px;
    //     background: #3266f6;
    //     &:hover {
    //       /* 选中状态 */
    //       box-shadow: 0.7px 7px 14px 3px rgba(218, 225, 236, 0.9),
    //         3.5220143795013428px 4.226417064666748px 7.748431205749512px 0px
    //           rgba(255, 255, 255, 0.25) inset;
    //     }
    //   }
    // }
    // and (min-width: 1440px)

    @media screen and (max-width: 1679px) {
      .tabItem {
        padding: 12px 28px;
        color: #3266f6;
        text-align: center;
        font-family: PingFang SC;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 24.84px */
        letter-spacing: 0.497px;
        transition: all 0.3s linear;
        &:hover {
          color: #fff;
          border-radius: 3px;
          background: #3266f6;
          &:hover {
            /* 选中状态 */
            box-shadow: 0.7px 7px 14px 3px rgba(218, 225, 236, 0.9),
              3.5220143795013428px 4.226417064666748px 7.748431205749512px 0px
                rgba(255, 255, 255, 0.25) inset;
          }
        }
      }
    }
    @media screen and (min-width: 1680px) {
      .tabItem {
        padding: 12px 28px;
        color: #3266f6;
        text-align: center;
        font-family: PingFang SC;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 24.84px */
        letter-spacing: 0.497px;
        transition: all 0.3s linear;
        &:hover {
          color: #fff;
          border-radius: 3px;
          background: #3266f6;
          &:hover {
            /* 选中状态 */
            box-shadow: 0.7px 7px 14px 3px rgba(218, 225, 236, 0.9),
              3.5220143795013428px 4.226417064666748px 7.748431205749512px 0px
                rgba(255, 255, 255, 0.25) inset;
          }
        }
      }
    }
  }
}
.giftBox {
  margin-top: 240px;
  .title {
    color: #000;
    text-align: center;
    font-family: PingFang SC;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 28px */
    letter-spacing: 0.56px;
    margin-bottom: 155px;
  }
  .leftItemBox {
    width: 1081px;
    margin: 0 auto 344px auto;
    position: relative;
    .leftBgImg {
      width: 465px;
      height: 390px;
      position: absolute;
      top: -317px;
      left: -350px;
    }
    .rightBgImg {
      width: 465px;
      height: 390px;
      position: absolute;
      top: -317px;
      right: -350px;
    }
    .imgItem {
      width: 481px;
      height: 352px;
      border-radius: 2px;
      opacity: 0;
    }
    .fadeInLeft,
    .fadeInRight {
      opacity: 1;
    }
    .infoBox:hover {
      box-shadow: 2px 2px 14px 0px #ccd6f0;
    }
    .infoBox {
      width: 481px;
      height: 352px;
      border-radius: 2px;
      border: 2px solid #fff;
      background: rgba(252, 252, 253, 0.85);
      padding: 19px 37px 0 22px;
      transition: all 0.3s linear;
      opacity: 0;
      .name {
        position: relative;
        &::before {
          content: "";
          background: #2354d1;
          width: 5px;
          height: 19px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        .name1 {
          color: #000;
          font-family: PingFang SC;
          font-size: 26px;
          font-style: normal;
          font-weight: 500;
          line-height: 1;
          letter-spacing: 0.52px;
        }
        .name2 {
          color: #000;
          font-family: PingFang SC;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 1;
          letter-spacing: 0.32px;
        }
      }
      .infoItem {
        color: #274159;
        font-family: PingFang SC;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.54px;
      }
      .more {
        color: #3266f6;
        font-family: PingFang SC;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 0%; /* 0px */
        letter-spacing: 0.36px;
        img {
          width: 20px;
        }
      }
    }
  }
}

.juruBox {
  width: 100%;
  height: 360px;
  background-image: url("https://oss.pinmallzj.com/image/maintain/2023/08/31/%E4%BC%81%E4%B8%9A%E5%95%86%E5%8A%A1%E4%BA%A7%E5%93%81%E9%A2%81%E5%A5%96%E5%85%B8%E7%A4%BC%E5%88%9B%E6%84%8F%E6%B8%85%E6%96%B0%E6%B4%BB%E5%8A%A8bannerx1-y5nq0b6sic79.png");
  background-size: cover;
  background-repeat: no-repeat;
  .chinese {
    width: 643px;
    height: auto;
  }
  .eng {
    width: 788px;
    height: auto;
  }
}
.zhanwei {
  height: 300px;
}
.juruBox {
  img {
    width: 100%;
    height: auto;
  }
}
.entApply {
  padding: 75px 0 77px 272px;
  background: #f8feff;
  @media screen and (min-width: 1680px) {
    img {
      width: 629px;
      height: 643px;
    }
    .rightBox {
      margin: 0 0 100px 157px;
      .title {
        color: #333;
        font-family: PingFang SC;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.3px;
      }
      .title1 {
        margin-top: 15px;
        color: #333;
        font-family: PingFang SC;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.211px;
      }
      .btn {
        margin-top: 50px;
        width: 232px;
        height: 52px;
        text-align: center;
        line-height: 52px;
        border-radius: 2px;
        border: 1px solid #fff;
        background: linear-gradient(180deg, #fafcff 0%, #fff 100%);
        transition: all 0.3s linear;
        color: #3d6bf6;
      }
      .btn:hover {
        box-shadow: 2px 2px 9px 0px #e4e9f7;
      }
    }
  }
  @media screen and (max-width: 1679px) {
    img {
      width: 500px;
      height: 511px;
    }
    .rightBox {
      margin: 0 0 100px 100px;
      .title {
        color: #333;
        font-family: PingFang SC;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.3px;
      }
      .title1 {
        margin-top: 15px;
        color: #333;
        font-family: PingFang SC;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.211px;
      }
      .btn {
        margin-top: 50px;
        width: 232px;
        height: 52px;
        text-align: center;
        line-height: 52px;
        border-radius: 2px;
        border: 1px solid #fff;
        background: linear-gradient(180deg, #fafcff 0%, #fff 100%);
        transition: all 0.3s linear;
        color: #3d6bf6;
      }
      .btn:hover {
        box-shadow: 2px 2px 9px 0px #e4e9f7;
      }
    }
  }
}
</style>
